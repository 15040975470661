import { makeStyles } from 'tss-react/mui';

export const usePlanCancellationModalStyles = makeStyles()(theme => ({
  modal: {
    maxWidth: 620,
  },
  modalContent: {
    position: 'relative',
    padding: theme.spacing(9, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  modalImage: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing(8),
  },
  textWrap: {
    padding: theme.spacing(0, 10),
    marginBottom: theme.spacing(2),
  },
  modalTitle: {
    fontSize: 35,
    marginBottom: theme.spacing(3),
  },
  modalCloseBtn: {
    position: 'absolute',
    top: theme.spacing(9.5),
    right: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(2),
      right: theme.spacing(-3),
    },
  },
  modalSuccessImage: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(2),
  },
  modalHint: {
    color: theme.palette.text.secondary,
  },
  modalHintFailed: {
    maxWidth: 460,
    margin: theme.spacing(4, 'auto', 0),
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '100%',
    marginTop: theme.spacing(6),
  },
  modalContactHint: {
    marginTop: theme.spacing(5),
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
}));
