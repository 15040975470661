import { useMemo } from 'react';

import { GRADE, IPlanItem } from '../../Rollup/rollupConst';
import { useGetPlansQuery } from '../../Rollup/screens/DeployRollup/actions/getPlans';

interface IUseGetCurrentPlan {
  stackUuid?: string;
  grade?: GRADE;
  planUuid?: string;
}

export const useGetCurrentPlan = ({
  stackUuid,
  grade,
  planUuid,
}: IUseGetCurrentPlan): IPlanItem | undefined => {
  const { data: plans } = useGetPlansQuery(
    {
      grade,
      stack: stackUuid,
    },
    {
      skip: !grade || !stackUuid,
    },
  );

  return useMemo(
    () => plans?.find(plan => plan.uuid === planUuid),
    [plans, planUuid],
  );
};
