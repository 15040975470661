import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { Gateways, mainApi } from '../../../../api';
import { GRADE, IDALItem } from '../../../rollupConst';
import { mapDALsFromApi } from '../utils/mapDALsFromApi';

interface IGetDALsProps {
  grade?: GRADE;
  stack?: string;
  isManualStack?: boolean;
}

export const { useGetDALsQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getDALs: build.query<IDALItem[], IGetDALsProps>({
      queryFn: queryFnNotifyWrapper<IGetDALsProps, never, IDALItem[]>(
        async ({ grade, stack, isManualStack }) => {
          if (grade && stack) {
            const { data } = await Gateways.noAuth.api.get(
              `/api/v1/data_availability_layers/${grade}/${stack}`,
              {
                method: 'GET',
              },
            );

            return {
              data: mapDALsFromApi({
                DALs: data,
                isManualStack,
              }),
            };
          }
        },
      ),
    }),
  }),
});
