import React, { JSX, useMemo } from 'react';
import { Chip } from '@ankr.com/ui';
import { Box, Tooltip } from '@mui/material';

interface IManualChipProps {
  label: string;
  chipClassName?: string;
  tooltip?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export function ManualChip({
  label,
  chipClassName,
  tooltip,
  tooltipPlacement = 'bottom',
}: IManualChipProps): JSX.Element {
  const renderedChip = useMemo(
    () => (
      <Chip
        label={label}
        size="small"
        color="secondary"
        className={chipClassName}
      />
    ),
    [chipClassName, label],
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        <Box fontSize={0}>{renderedChip}</Box>
      </Tooltip>
    );
  }

  return renderedChip;
}
