import { useMemo } from 'react';

import { GRADE, IDALItem } from '../../Rollup/rollupConst';
import { useGetDALsQuery } from '../../Rollup/screens/DeployRollup/actions/getDALs';

interface IUseGetCurrentDAL {
  stackUuid?: string;
  grade?: GRADE;
  DALUuid?: string;
}

export const useGetCurrentDAL = ({
  stackUuid,
  grade,
  DALUuid,
}: IUseGetCurrentDAL): IDALItem | undefined => {
  const { data: DALs } = useGetDALsQuery(
    {
      grade,
      stack: stackUuid,
    },
    {
      skip: !grade || !stackUuid,
    },
  );

  return useMemo(
    () => DALs?.find(DAL => DAL.uuid === DALUuid),
    [DALs, DALUuid],
  );
};
