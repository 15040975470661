import { useMemo } from 'react';

import { IStackItem } from '../../Rollup/rollupConst';
import { useGetStacksQuery } from '../../Rollup/screens/DeployRollup/actions/getStacks';

interface IUseGetCurrentStack {
  stackUuid?: string;
}

export const useGetCurrentStack = ({
  stackUuid,
}: IUseGetCurrentStack): IStackItem | undefined => {
  const { data: stacks } = useGetStacksQuery();

  return useMemo(
    () => stacks?.find(stack => stack.uuid === stackUuid),
    [stackUuid, stacks],
  );
};
