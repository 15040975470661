import React, { JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppModal, ImageX } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Close, LoadingButton } from '@ankr.com/ui';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useQueryParam } from 'use-query-params';

import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import {
  GRADE,
  IRollupDeployed,
  ROLLUP_STATUS,
} from '../../../Rollup/rollupConst';
import { useExtendRollupMutation } from '../../../Rollup/screens/Rollup/actions/extendRollup';
import { DialogId } from '../../actions/openDialog';
import imgUrl from '../../assets/success.png';
import imgUrl2x from '../../assets/success@2x.png';
import { ALLOW_CRYPTO_PAYMENTS } from '../../const/values';
import { useDialog } from '../../hooks/useDialog';
import { PaymentPoweredBy, PaymentTypeSwitcher } from '../PaymentTypes';
import { paymentStatusTranslation } from './translation';
import { usePaymentStatusModalsStyles } from './usePaymentStatusModalsStyles';

interface IPaymentStatusModalsProps {
  rollup?: IRollupDeployed;
}

export function PaymentStatusModals({
  rollup,
}: IPaymentStatusModalsProps): JSX.Element {
  const { classes } = usePaymentStatusModalsStyles();

  const { keys, t } = useTranslation(paymentStatusTranslation);

  const [success, setSuccess] = useQueryParam<
    boolean | 'true' | 'false' | undefined
  >('success');
  const [failed, setFailed] = useQueryParam<
    boolean | 'true' | 'false' | undefined
  >('failed');

  const {
    open: successModalOpen,
    handleClose: handleSuccessModalClose,
    handleOpen: handleSuccessModalOpen,
  } = useDialog(DialogId.BillingSuccess);
  const {
    open: failedModalOpen,
    handleClose: handleFailedModalClose,
    handleOpen: handleFailedModalOpen,
  } = useDialog(DialogId.BillingFailed);

  useEffect(() => {
    if (success && success === 'true') {
      handleSuccessModalOpen();
      setSuccess(undefined, 'replaceIn');
    }
  }, [handleSuccessModalOpen, setSuccess, success]);
  useEffect(() => {
    if (failed && failed === 'true') {
      handleFailedModalOpen();
      setFailed(undefined, 'replaceIn');
    }
  }, [failed, handleFailedModalOpen, setFailed]);

  const { handleOpen: handleRollupDepositModalOpen } = useDialog(
    DialogId.RollupDeposit,
  );

  const hasPrice = useMemo(
    () => rollup?.plan?.price && !rollup?.plan?.price.isZero(),
    [rollup?.plan?.price],
  );

  const onDepositModalClick = useCallback(() => {
    handleSuccessModalClose();
    handleRollupDepositModalOpen();
  }, [handleRollupDepositModalOpen, handleSuccessModalClose]);

  const navigate = useNavigate();

  const [
    extendRollup,
    {
      data: extendRollupResult,
      isLoading: isExtendRollupLoading,
      isSuccess: isExtendRollupSuccess,
    },
  ] = useExtendRollupMutation();

  useEffect(() => {
    if (isExtendRollupSuccess) {
      if (extendRollupResult?.subscription?.url) {
        handleFailedModalClose();
        window.location.assign(extendRollupResult.subscription.url);
      } else {
        handleFailedModalClose();
        navigate(DashboardRoutesConfig.Dashboard.generatePath());
      }
    }
  }, [
    extendRollupResult?.subscription?.url,
    handleFailedModalClose,
    isExtendRollupSuccess,
    navigate,
  ]);

  const [paymentType, setPaymentType] = useState(rollup?.paymentType);

  const onSubmitClick = useCallback(async () => {
    await extendRollup({
      uuid: rollup?.uuid,
      paymentType,
    });
  }, [extendRollup, paymentType, rollup?.uuid]);

  const successModalHint = useLocaleMemo(() => {
    if (rollup?.status === ROLLUP_STATUS.initializing) {
      if (hasPrice) {
        return t(keys.successModal.paidInitializingHint, {}, true);
      }
      return t(keys.successModal.freeInitializingHint, {}, true);
    }

    if (rollup?.status === ROLLUP_STATUS.pending_deposit) {
      if (rollup?.plan.grade === GRADE.mainnet) {
        return t(
          keys.successModal.successPendingDepositMainnetHint,
          {
            amount: rollup?.deposits?.parsedForUI.amountsWithToken?.join(
              tCommon('common.plus-sign-spaced'),
            ),
          },
          true,
        );
      }
      if (hasPrice) {
        return t(
          keys.successModal.successPendingDepositPaidTestnetHint,
          {
            amount: rollup?.deposits?.parsedForUI.amountsWithToken?.join(
              tCommon('common.plus-sign-spaced'),
            ),
          },
          true,
        );
      }
      return t(
        keys.successModal.successPendingDepositFreeTestnetHint,
        {
          amount: rollup?.deposits?.parsedForUI.amountsWithToken?.join(
            tCommon('common.plus-sign-spaced'),
          ),
        },
        true,
      );
    }
    if (
      rollup?.status !== ROLLUP_STATUS.initializing &&
      rollup?.status !== ROLLUP_STATUS.pending_deposit
    ) {
      return t(keys.successModal.successInitializedHint, {}, true);
    }
    return t(keys.successModal.successHint, {}, true);
  }, [
    rollup?.status,
    rollup?.plan.grade,
    rollup?.deposits?.parsedForUI.amountsWithToken,
    t,
    keys.successModal.successHint,
    keys.successModal.freeInitializingHint,
    keys.successModal.paidInitializingHint,
    keys.successModal.successPendingDepositFreeTestnetHint,
    keys.successModal.successPendingDepositMainnetHint,
    keys.successModal.successPendingDepositPaidTestnetHint,
    keys.successModal.successInitializedHint,
    hasPrice,
  ]);

  const successModalButton = useLocaleMemo(() => {
    if (rollup?.status === ROLLUP_STATUS.initializing) {
      return (
        <LoadingButton
          loading
          size="large"
          fullWidth
          className={classes.modalBtn}
        >
          {t(keys.successModal.initializing)}
        </LoadingButton>
      );
    }
    if (rollup?.status === ROLLUP_STATUS.pending_deposit) {
      return (
        <Button
          onClick={onDepositModalClick}
          size="large"
          fullWidth
          className={classes.modalBtn}
        >
          {t(keys.successModal.deposit, {
            amount: rollup?.deposits?.parsedForUI.amountsWithToken?.join(
              tCommon('common.plus-sign-spaced'),
            ),
          })}
        </Button>
      );
    }
    return (
      <Button
        onClick={handleSuccessModalClose}
        size="large"
        fullWidth
        className={classes.modalBtn}
      >
        {t(keys.successModal.button)}
      </Button>
    );
  }, [
    classes.modalBtn,
    rollup?.deposits?.parsedForUI.amountsWithToken,
    rollup?.status,
    handleSuccessModalClose,
    keys.successModal.button,
    keys.successModal.deposit,
    keys.successModal.initializing,
    onDepositModalClick,
    t,
  ]);

  return (
    <>
      <AppModal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        maxWidth="xs"
        withoutCloseButton
        classes={{ paper: classes.modal }}
      >
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handleSuccessModalClose}
            color="inherit"
            className={classes.modalCloseBtn}
          >
            <Close sx={{ color: 'inherit' }} />
          </IconButton>

          <ImageX
            imgUrl={imgUrl}
            img2xUrl={imgUrl2x}
            className={classes.modalImage}
          />

          <Typography variant="h5">{t(keys.successModal.title)}</Typography>
          <Typography variant="body3" className={classes.modalHint}>
            {successModalHint}
          </Typography>

          {!!hasPrice && (
            <>
              <hr />
              <Box
                display="flex"
                gap={2}
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">
                  {t(keys.successModal.paidAmount)}
                </Typography>
                <Typography variant="subtitle2">
                  {tCommon('number.price', { value: rollup?.plan?.price })}
                </Typography>
              </Box>
            </>
          )}
          {successModalButton}
        </Box>
      </AppModal>
      <AppModal
        open={failedModalOpen}
        onClose={handleFailedModalClose}
        maxWidth="xs"
        title={t(keys.failedModal.title)}
        titleClassName={classes.failedModalTitle}
        titleCentered
        classes={{ paper: classes.modal }}
      >
        <Box className={classes.failedModalContent}>
          <Box className={classes.failedModalHint}>
            <Typography variant="body3" className={classes.modalHint}>
              {t(keys.failedModal.hint1)}
            </Typography>
            <Typography variant="body3" className={classes.modalHint}>
              {t(keys.failedModal.hint2)}
            </Typography>
          </Box>

          {ALLOW_CRYPTO_PAYMENTS && !!hasPrice && (
            <PaymentTypeSwitcher
              paymentType={paymentType}
              handleSwitch={setPaymentType}
              className={classes.paymentType}
            />
          )}

          <LoadingButton
            size="large"
            onClick={onSubmitClick}
            loading={isExtendRollupLoading}
            fullWidth
          >
            {hasPrice
              ? t(keys.failedModal.tryAgainWithPrice, {
                  price: tCommon('number.price', {
                    value: rollup?.plan?.price,
                  }),
                })
              : t(keys.failedModal.tryAgain)}
          </LoadingButton>

          {!!hasPrice && (
            <PaymentPoweredBy
              paymentType={paymentType}
              className={classes.poweredBy}
            />
          )}
        </Box>
      </AppModal>
    </>
  );
}
