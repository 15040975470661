import React, { JSX, SyntheticEvent, useCallback } from 'react';
import { ContentSwitcher, CreditCard, CryptoWalletIcon } from '@ankr.com/ui';
import { Box, Tab } from '@mui/material';

import { useTranslation } from '../../../../i18n';
import { PAYMENT_TYPE } from '../../../../Rollup/rollupConst';
import { paymentTypeSwitcherTranslation } from './translation';

interface IPaymentTypeSwitcherProps {
  paymentType?: PAYMENT_TYPE;
  handleSwitch: (newValue: PAYMENT_TYPE) => void;
  className?: string;
}

export function PaymentTypeSwitcher({
  paymentType,
  handleSwitch,
  className,
}: IPaymentTypeSwitcherProps): JSX.Element {
  const { keys, t } = useTranslation(paymentTypeSwitcherTranslation);

  const onSwitch = useCallback(
    (_event: SyntheticEvent, newValue: PAYMENT_TYPE): void => {
      handleSwitch(newValue);
    },
    [handleSwitch],
  );

  return (
    <Box display="flex" className={className}>
      <ContentSwitcher
        value={paymentType}
        onChange={onSwitch}
        variant="fullWidth"
        sx={{ width: '100%' }}
      >
        <Tab
          value={PAYMENT_TYPE.stripe}
          label={t(keys.creditCard)}
          iconPosition="start"
          icon={<CreditCard sx={{ color: 'inherit' }} />}
        />
        <Tab
          value={PAYMENT_TYPE.loop}
          label={t(keys.crypto)}
          iconPosition="start"
          icon={<CryptoWalletIcon sx={{ color: 'inherit' }} />}
        />
      </ContentSwitcher>
    </Box>
  );
}
