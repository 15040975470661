import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { CMSGateways } from '../../../../api';
import { cmsApi } from '../../../../api/cmsApi';
import { IAccountInfo } from '../../../../common/components/AccountInfoForm/actions/types';
import { ENV } from '../../../../common/const/environment';
import {
  CMS_MANUAL_DEPLOYMENTS_API_URL,
  PROJECT_KEY,
} from '../../../../common/const/values';
import {
  IDeployRollupFormPayload,
  IDeployRollupResponseApi,
} from '../../../rollupConst';

interface IPostRollupManualDeployProps {
  formData: IDeployRollupFormPayload;
  accountData?: IAccountInfo;
}

export const { usePostRollupManualDeployMutation } = cmsApi.injectEndpoints({
  endpoints: build => ({
    postRollupManualDeploy: build.mutation<
      IDeployRollupResponseApi,
      IPostRollupManualDeployProps
    >({
      queryFn: queryFnNotifyWrapper<
        IPostRollupManualDeployProps,
        never,
        IDeployRollupResponseApi
      >(async ({ formData, accountData }: IPostRollupManualDeployProps) => {
        const res = await CMSGateways.noAuth.api.post(
          CMS_MANUAL_DEPLOYMENTS_API_URL,
          JSON.stringify({
            data: {
              ...accountData,
              ...formData,
              chainId: formData.chainId?.toString(),
              application: `${PROJECT_KEY} ${ENV}`,
            },
          }),
        );

        return { data: res.data };
      }),
    }),
  }),
});
