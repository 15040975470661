import { mainApi } from '../../api';

export const DialogId = {
  RollupPlans: 'rollup-plans',
  RollupConfirm: 'rollup-confirm',
  RollupTestnetProve: 'rollup-testnet-prove',
  RollupPay: 'rollup-pay',
  RollupDeposit: 'rollup-deposit',
  RollupDelete: 'rollup-delete',
  RollupRPCEndpoints: 'rollup-RPC-endpoints',
  RollupBridge: 'rollup-bridge',
  ExtendedRollupTestnet: 'extended-rollup-testnet',
  PlanCancel: 'plan-cancel',
  AccountDelete: 'account-delete',
  BillingSuccess: 'billing-success',
  BillingFailed: 'billing-failed',
  ExplorerSettings: 'explorer-settings',
};

export type DialogId = (typeof DialogId)[keyof typeof DialogId];

export const { useOpenDialogMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    openDialog: build.mutation<{ open: boolean }, { open: boolean }>({
      queryFn: ({ open }) => {
        return { data: { open } };
      },
    }),
  }),
});
