import { useMemo } from 'react';

import { DECLARED_CHAIN, DECLARED_STACK_KEYS } from '../../Rollup/rollupConst';
import { useGetCurrentStack } from './useGetCurrentStack';

interface IUseGetL1ChainByStack {
  stackUuid?: string;
  isMainnet: boolean;
}

export const useGetL1ChainByStack = ({
  stackUuid,
  isMainnet,
}: IUseGetL1ChainByStack): DECLARED_CHAIN | undefined => {
  const currentStack = useGetCurrentStack({
    stackUuid,
  });

  return useMemo(() => {
    if (!currentStack) {
      return undefined;
    }

    if (currentStack?.key === DECLARED_STACK_KEYS.fraxtal) {
      if (isMainnet) {
        return DECLARED_CHAIN.frax;
      }
      return DECLARED_CHAIN.frax_holesky;
    }
    if (isMainnet) {
      return DECLARED_CHAIN.eth;
    }
    return DECLARED_CHAIN.holesky;
  }, [currentStack, isMainnet]);
};
