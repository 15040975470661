import { DECLARED_DAL_KEYS, IDALItem, IDALItemApi } from '../../../rollupConst';

interface IMapDALsFromApiProps {
  DALs: IDALItemApi[];
  isManualStack?: boolean;
}

export function mapDALFromApi(DAL: IDALItemApi): IDALItem {
  return {
    description: DAL.description,
    key: DAL.key || '',
    title: DAL.title,
    uuid: DAL.uuid,
    comingSoon: DAL.coming_soon,
    gasTokens: DAL.gas_tokens.map(gasToken => ({
      uuid: gasToken.uuid,
      title: gasToken.title,
      key: gasToken.key,
      comingSoon: gasToken.coming_soon,
    })),
    hasGrants: DAL.has_grants,
  };
}

export function mapDALsFromApi({
  DALs,
  isManualStack,
}: IMapDALsFromApiProps): IDALItem[] {
  const mapped = DALs?.map((DAL: IDALItemApi) => mapDALFromApi(DAL));

  if (isManualStack) {
    return mapped;
  }

  return [
    ...mapped
      // Show 'Instants' first
      .filter(x => !x.comingSoon)
      // Show 'Ethereum DA' first
      .sort(a => {
        if (a.key === DECLARED_DAL_KEYS.ethereum) {
          return -1;
        }
        return 0;
      }),
    ...mapped.filter(x => x.comingSoon),
  ];
}
