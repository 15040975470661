import React, { JSX } from 'react';
import { ImageX } from '@ankr.com/raas-ui';
import { Box, Typography } from '@mui/material';

import { useTranslation } from '../../../../i18n';
import { PAYMENT_TYPE } from '../../../../Rollup/rollupConst';
import loopLogoUrl from './assets/loopLogo.svg';
import stripeLogoUrl from './assets/stripeLogo.svg';
import { paymentPoweredByTranslation } from './translation';
import { usePaymentPoweredByStyles } from './usePaymentPoweredByStyles';

interface IPaymentTypeSwitcherProps {
  paymentType?: PAYMENT_TYPE;
  className?: string;
}

export function PaymentPoweredBy({
  paymentType,
  className,
}: IPaymentTypeSwitcherProps): JSX.Element | null {
  const { classes, cx } = usePaymentPoweredByStyles();

  const { keys, t } = useTranslation(paymentPoweredByTranslation);

  if (!paymentType) {
    return null;
  }

  return (
    <Typography
      variant="body4"
      className={cx(classes.root, className)}
      component={Box}
    >
      {t(keys.poweredBy)}
      <ImageX
        imgUrl={
          paymentType === PAYMENT_TYPE.stripe ? stripeLogoUrl : loopLogoUrl
        }
        className={classes.img}
      />
    </Typography>
  );
}
