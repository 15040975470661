import { IStackItem, IStackItemApi } from '../../../rollupConst';

export function mapStackFromApi(stack: IStackItemApi): IStackItem {
  return {
    description: stack.description,
    key: stack.key || '',
    title: stack.title,
    uuid: stack.uuid,
    comingSoon: stack.coming_soon,
    mainnetComingSoon: stack.mainnet_coming_soon,
    hasGrants: stack.has_grants,
  };
}

export function mapStacksFromApi(stacks: IStackItemApi[]): IStackItem[] {
  return stacks?.map((stack: IStackItemApi) => mapStackFromApi(stack));
}
