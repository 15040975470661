import { MAX_FREE_TESTNET_ROLLUPS } from '../../../../common/const/values';
import { useGetRollupDeploysQuery } from '../../../../Dashboard/actions/getRollupDeploys';
import { GRADE, ROLLUP_STATUS } from '../../../rollupConst';

export const useHasMaxFreeTestnetRollups = () => {
  const { data: rollupListData } = useGetRollupDeploysQuery();

  const freeTestnetRollups =
    rollupListData?.filter(rollup => {
      const expiredDate = rollup.paidUntil?.getTime() || 0;
      const now = Date.now();
      const isExpiredByTime = expiredDate <= now;

      return (
        rollup.plan.grade === GRADE.testnet &&
        (!rollup.plan.price || rollup.plan.price.isZero()) &&
        (rollup.status === ROLLUP_STATUS.expired || isExpiredByTime
          ? rollup.extendable
          : true)
      );
    }).length || 0;

  return freeTestnetRollups >= MAX_FREE_TESTNET_ROLLUPS;
};
