import React, { JSX } from 'react';
import { tCommon } from '@ankr.com/raas-utils';

import { DECLARED_TOKEN } from '../../Rollup/rollupConst';
import { HOMEPAGE } from '../const/values';

interface IGetTokenProps {
  value?: string;
  imageClassName?: string;
}

export function getToken({ value, imageClassName }: IGetTokenProps): {
  name: string;
  imageSrc?: string;
  image: JSX.Element | null;
} {
  if (!value) {
    return {
      name: '',
      imageSrc: undefined,
      image: null,
    };
  }

  const valueLower = value.toLowerCase();
  const isDeclared = Object.hasOwn(DECLARED_TOKEN, valueLower);

  const imageSrc = isDeclared
    ? `${HOMEPAGE}static/token/${valueLower}.svg`
    : `${HOMEPAGE}static/token/undefined.svg`;

  return {
    name: isDeclared ? tCommon(`token.${valueLower}`) : value,
    imageSrc,
    image: isDeclared ? (
      <img src={imageSrc} alt="" className={imageClassName} />
    ) : null,
  };
}
