import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../../../api';
import {
  IDeployRollupFormPayload,
  IDeployRollupResponseApi,
} from '../../../rollupConst';
import { mapRollupFormForApi } from '../utils/mapRollupFormForApi';

export const { usePostRollupDeployMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    postRollupDeploy: build.mutation<
      IDeployRollupResponseApi,
      IDeployRollupFormPayload
    >({
      queryFn: queryFnNotifyWrapper<
        IDeployRollupFormPayload,
        never,
        IDeployRollupResponseApi
      >(async payload => {
        const res = await Gateways.auth.api.post(
          '/api/v1/deployments',
          JSON.stringify(mapRollupFormForApi(payload)),
        );

        return { data: res.data };
      }),
      invalidatesTags: [cacheTags.rollups],
    }),
  }),
});
