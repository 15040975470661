import {
  IExplorerSettingsApi,
  IExplorerSettingsPayload,
} from '../explorerSettingsConst';

export function mapExplorerSettingsToApi(
  data: IExplorerSettingsPayload,
): IExplorerSettingsApi {
  const background = data.background ? [data.background.trim()] : undefined;
  if (
    background &&
    data.backgroundDark &&
    data.background !== data.backgroundDark
  ) {
    background.push(data.backgroundDark.trim());
  }

  const textColor = data.textColor ? [data.textColor.trim()] : undefined;
  if (
    textColor &&
    data.textColorDark &&
    data.textColor !== data.textColorDark
  ) {
    textColor.push(data.textColorDark.trim());
  }

  return {
    NEXT_PUBLIC_VIEWS_ADDRESS_IDENTICON_TYPE:
      data.addressIcon?.trim() || undefined,
    NEXT_PUBLIC_COLOR_THEME_DEFAULT: data.theme?.trim() || undefined,
    NEXT_PUBLIC_NAVIGATION_LAYOUT: data.navigation?.trim() || undefined,
    NEXT_PUBLIC_NETWORK_LOGO: data.logo?.trim() || undefined,
    NEXT_PUBLIC_NETWORK_LOGO_DARK: data.logoDark?.trim() || undefined,
    NEXT_PUBLIC_NETWORK_ICON: data.icon?.trim() || undefined,
    NEXT_PUBLIC_NETWORK_ICON_DARK: data.iconDark?.trim() || undefined,
    NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG:
      !!background || !!textColor
        ? {
            background,
            text_color: textColor,
          }
        : undefined,
    NEXT_PUBLIC_OG_IMAGE_URL: data.ogImage?.trim() || undefined,
  };
}
