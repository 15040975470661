import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../../../api';

interface ICancelSubscriptionProps {
  uuid: string;
}

export const { useCancelSubscriptionMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    cancelSubscription: build.mutation<null, ICancelSubscriptionProps>({
      queryFn: queryFnNotifyWrapper<ICancelSubscriptionProps, never, null>(
        async ({ uuid }) => {
          await Gateways.auth.api.post(`/api/v1/deployments/${uuid}/cancel`);

          return { data: null };
        },
      ),
      invalidatesTags: [cacheTags.rollups],
    }),
  }),
});
