import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../../../../store/useAppDispatch';
import { GRADE, IDeployRollupFormPayload } from '../../../rollupConst';
import { updateFormData } from '../store/rollupDeploySlice';
import {
  RollupDeployStepsEnum,
  updateStepsData,
} from '../store/rollupDeployStepsSlice';
import { useRollupDeployState } from './useRollupDeployState';
import { useRollupDeployStepsState } from './useRollupDeployStepsState';

export const useDispatchRollupDeployData = () => {
  const dispatch = useAppDispatch();

  const rollupDeployStepsState = useRollupDeployStepsState();

  const rollupDeployState = useRollupDeployState();

  const isMainnet = useMemo(
    () => rollupDeployState.grade === GRADE.mainnet,
    [rollupDeployState.grade],
  );

  const dispatchData = useCallback(
    (data: IDeployRollupFormPayload, increaseStep = false) => {
      dispatch(updateFormData(data));

      const delta =
        rollupDeployStepsState.step === RollupDeployStepsEnum.General &&
        !isMainnet
          ? 2
          : 1;

      if (
        increaseStep &&
        rollupDeployStepsState.step !== RollupDeployStepsEnum.InfraPackage
      ) {
        dispatch(
          updateStepsData({
            step: rollupDeployStepsState.step + delta,
            passedStep:
              rollupDeployStepsState.step > rollupDeployStepsState.passedStep
                ? rollupDeployStepsState.step
                : rollupDeployStepsState.passedStep,
          }),
        );
      }
    },
    [
      dispatch,
      isMainnet,
      rollupDeployStepsState.passedStep,
      rollupDeployStepsState.step,
    ],
  );

  return { dispatchData };
};
