/* eslint-disable camelcase */
import { Web3Address } from '@ankr.com/raas-types';
import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { Gateways, mainApi } from '../../../../api';

interface IGetCustomGasTokenAddressValidationProps {
  planUuid?: string;
  address?: Web3Address;
}

interface IValidateTokenApi {
  is_invalid: boolean;
  is_erc_20?: boolean;
  is_18_decimals?: boolean;
  is_non_rebasing?: boolean;
}

export interface IValidateTokenLevels {
  isErc20?: boolean;
  is18Decimals?: boolean;
  isNonRebasing?: boolean;
}

interface IValidateToken extends IValidateTokenLevels {
  isInvalid: boolean;
}

export const { useLazyGetCustomGasTokenAddressValidationQuery } =
  mainApi.injectEndpoints({
    endpoints: build => ({
      getCustomGasTokenAddressValidation: build.query<
        IValidateToken,
        IGetCustomGasTokenAddressValidationProps
      >({
        queryFn: queryFnNotifyWrapper<
          IGetCustomGasTokenAddressValidationProps,
          never,
          IValidateToken
        >(async ({ planUuid, address }) => {
          if (planUuid && address) {
            const { data }: { data: IValidateTokenApi } =
              await Gateways.noAuth.api.get(
                `/api/v1/validate/token/${planUuid}/${address}`,
                {
                  method: 'GET',
                },
              );

            return {
              data: {
                isInvalid: data.is_invalid,
                isErc20: data.is_erc_20,
                is18Decimals: data.is_18_decimals,
                isNonRebasing: data.is_non_rebasing,
              },
            };
          }
        }),
      }),
    }),
  });
