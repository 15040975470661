import { useCallback } from 'react';
import { Check } from '@ankr.com/ui';
import { Box } from '@mui/material';

import { useAppDispatch } from '../../../../../../store/useAppDispatch';
import { useLocaleMemo, useTranslation } from '../../../../../i18n';
import { GRADE } from '../../../../rollupConst';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { useRollupDeployStepsState } from '../../hooks/useRollupDeployStepsState';
import { updateStepsData } from '../../store/rollupDeployStepsSlice';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStepperStyles } from './useDeployRollupStepperStyles';

export function DeployRollupStepper() {
  const { classes, cx } = useDeployRollupStepperStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { grade } = useRollupDeployState();

  const dispatch = useAppDispatch();

  const rollupDeployStepsState = useRollupDeployStepsState();

  const steps = useLocaleMemo(
    () => [
      t(keys.deployRollupStepper.general),
      grade === GRADE.mainnet
        ? t(keys.deployRollupStepper.sequencer)
        : undefined,
      t(keys.deployRollupStepper.configuration),
      t(keys.deployRollupStepper.infra),
    ],
    [
      keys.deployRollupStepper.configuration,
      keys.deployRollupStepper.general,
      keys.deployRollupStepper.infra,
      keys.deployRollupStepper.sequencer,
      grade,
      t,
    ],
  );

  const handleClick = useCallback(
    ({
      stepNumber,
      isClickable,
    }: {
      stepNumber: number;
      isClickable: boolean;
    }) => {
      if (isClickable) {
        dispatch(
          updateStepsData({
            step: stepNumber,
            passedStep: rollupDeployStepsState.passedStep,
          }),
        );
      }
    },
    [dispatch, rollupDeployStepsState.passedStep],
  );

  return (
    <Box className={classes.root}>
      <Box component="ol" className={classes.list}>
        {steps.map((step, index) => {
          const stepNumber = index + 1;
          const isPassed = stepNumber < rollupDeployStepsState.step;
          const isCurrent = stepNumber === rollupDeployStepsState.step;
          const isClickable = isPassed && !isCurrent;

          if (!step) {
            return null;
          }

          return (
            <Box
              key={`step_${step}${stepNumber}`}
              component="li"
              className={cx(
                classes.step,
                isCurrent && classes.stepCurrent,
                isPassed && classes.stepPassed,
                isClickable && classes.stepClickable,
              )}
              onClick={() => handleClick({ stepNumber, isClickable })}
            >
              {isPassed && <Check className={classes.icon} />}
              <Box className={classes.stepNumber} />
              <Box className={classes.stepTitle}>{step}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
