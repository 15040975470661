import {
  IExplorerSettingsApi,
  IExplorerSettingsPayload,
} from '../explorerSettingsConst';

export function mapExplorerSettingsFromApi(
  payload: IExplorerSettingsApi,
): IExplorerSettingsPayload {
  return {
    addressIcon: payload.NEXT_PUBLIC_VIEWS_ADDRESS_IDENTICON_TYPE,
    theme: payload.NEXT_PUBLIC_COLOR_THEME_DEFAULT,
    navigation: payload.NEXT_PUBLIC_NAVIGATION_LAYOUT,
    logo: payload.NEXT_PUBLIC_NETWORK_LOGO,
    logoDark: payload.NEXT_PUBLIC_NETWORK_LOGO_DARK,
    icon: payload.NEXT_PUBLIC_NETWORK_ICON,
    iconDark: payload.NEXT_PUBLIC_NETWORK_ICON_DARK,
    background:
      payload.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.background?.[0],
    backgroundDark:
      payload.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.background?.[1],
    textColor: payload.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.text_color?.[0],
    textColorDark:
      payload.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.text_color?.[1],
    ogImage: payload.NEXT_PUBLIC_OG_IMAGE_URL,
  };
}
