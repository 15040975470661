import { Locale } from '../i18n';

export const billingTranslation = {
  [Locale.en]: {
    plan: {
      title: 'Your Plan',
      nextPayment: 'Next payment: {value}',
      pay: 'Pay {price}',
    },
    includes: {
      title: 'Plan Includes',
    },
    history: {
      title: 'Payments history',
      date: 'Date',
      type: 'Payment type',
      amount: 'Amount',
    },
    emptyTitle: 'No paid projects added yet',
  },
};
