import React, { JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { AppModal, notification } from '@ankr.com/raas-ui';
import {
  getErrorMessage,
  tCommon,
  truncateWalletAddress,
} from '@ankr.com/raas-utils';
import { ExternalLink, LoadingButton } from '@ankr.com/ui';
import { Alert, Box, Button, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useGetAccountInfoQuery } from '../../../../../common/components/AccountInfoForm/actions/getAccountInfo';
import { GradeChip } from '../../../../../common/components/GradeChip';
import {
  PaymentPoweredBy,
  PaymentTypeSwitcher,
} from '../../../../../common/components/PaymentTypes';
import { StackDALIcon } from '../../../../../common/components/StackDALIcon';
import {
  ALLOW_CRYPTO_PAYMENTS,
  HUBSPOT_URL,
  TERMS_OF_USE_URL,
} from '../../../../../common/const/values';
import { useDepositsForPlan } from '../../../../../common/hooks/useDepositsForPlan';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useGetCurrentDAL } from '../../../../../common/hooks/useGetCurrentDAL';
import { useGetCurrentStack } from '../../../../../common/hooks/useGetCurrentStack';
import { getToken } from '../../../../../common/utils/getToken';
import { useTranslation } from '../../../../../i18n';
import {
  DECLARED_TOKEN,
  GRADE,
  IDALItem,
  IDeployRollupFormPayload,
  IPlanItem,
  IStackItem,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../../../rollupConst';
import { usePostRollupDeployMutation } from '../../actions/postRollupDeploy';
import { usePostRollupManualDeployMutation } from '../../actions/postRollupManualDeploy';
import { useIsManualDeployment } from '../../hooks/useIsManualDeployment';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';

interface IDeployRollupConfirmModalProps {
  rollupDeployState: IDeployRollupFormPayload;
  currentPlan?: IPlanItem;
}

export function DeployRollupConfirmModal({
  rollupDeployState,
  currentPlan,
}: IDeployRollupConfirmModalProps): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const currentStack = useGetCurrentStack({
    stackUuid: rollupDeployState.stack,
  });

  const currentDAL = useGetCurrentDAL({
    DALUuid: rollupDeployState.dataAvailabilityLayer,
    stackUuid: rollupDeployState.stack,
    grade: rollupDeployState.grade,
  });

  const currentRPCAmount = useMemo(
    () => currentPlan?.settings?.find(setting => setting.key === 'rpc_amount'),
    [currentPlan?.settings],
  );

  const { imageSrc: gasTokenLogo, name: gasTokenName } = getToken({
    value: rollupDeployState.gasToken,
  });

  const depositAmount = useDepositsForPlan({
    deposits: currentPlan?.deposits,
    gasTokenType: rollupDeployState.gasToken,
  });

  const [
    postRollupDeployment,
    {
      data: postRollupDeploymentResult,
      isLoading: isPostRollupDeploymentLoading,
      isSuccess: isPostRollupDeploymentSuccess,
    },
  ] = usePostRollupDeployMutation();

  const { open: rollupConfirmOpen, handleClose: handleRollupConfirmClose } =
    useDialog(DialogId.RollupConfirm);

  const { handleOpen: handleRollupTestnetProveOpen } = useDialog(
    DialogId.RollupTestnetProve,
  );

  const hasPrice = useMemo(
    () => currentPlan?.price && !currentPlan.price.isZero(),
    [currentPlan?.price],
  );

  const [paymentType, setPaymentType] = useState(
    rollupDeployState?.paymentType,
  );

  const handleConfirm = useCallback(async () => {
    if (hasPrice) {
      await postRollupDeployment({
        ...rollupDeployState,
        paymentType,
      });
    } else {
      handleRollupConfirmClose();
      handleRollupTestnetProveOpen();
    }
  }, [
    hasPrice,
    postRollupDeployment,
    rollupDeployState,
    paymentType,
    handleRollupConfirmClose,
    handleRollupTestnetProveOpen,
  ]);

  useEffect(() => {
    if (isPostRollupDeploymentSuccess) {
      if (!!hasPrice && postRollupDeploymentResult?.subscription?.url) {
        handleRollupConfirmClose();
        window.location.assign(postRollupDeploymentResult.subscription.url);
      }
    }
  }, [
    hasPrice,
    handleRollupConfirmClose,
    isPostRollupDeploymentSuccess,
    postRollupDeploymentResult?.subscription?.url,
  ]);

  const { data: accountData } = useGetAccountInfoQuery();

  const [
    postRollupManualDeployment,
    { isLoading: isPostRollupManualDeploymentLoading },
  ] = usePostRollupManualDeployMutation();

  const getPreparedString = useCallback(
    (payload: IStackItem | IDALItem | IPlanItem | undefined) => {
      if (!payload) {
        return 'unknown';
      }
      let string = '';
      if ('title' in payload && !!payload?.title) {
        string += payload.title;
      }
      if ('key' in payload && !!payload?.key) {
        string += ` [${payload.key}]`;
      }
      if ('uuid' in payload && !!payload?.uuid) {
        string += `; UUID: ${payload.uuid}`;
      }
      return string;
    },
    [],
  );

  const handleConfirmManual = useCallback(async () => {
    try {
      await postRollupManualDeployment({
        formData: {
          ...rollupDeployState,
          stack: getPreparedString(currentStack),
          dataAvailabilityLayer: getPreparedString(currentDAL),
          planUuid: getPreparedString(currentPlan),
          paymentType,
        },
        accountData,
      });
      handleRollupConfirmClose();
      window.location.assign(HUBSPOT_URL);
    } catch (error) {
      notification({
        title: tCommon('error.error'),
        message: getErrorMessage(error) || tCommon('error.unexpected'),
        type: 'error',
      });
    }
  }, [
    accountData,
    currentDAL,
    currentPlan,
    currentStack,
    getPreparedString,
    handleRollupConfirmClose,
    paymentType,
    postRollupManualDeployment,
    rollupDeployState,
  ]);

  const isManualDeployment = useIsManualDeployment();

  return (
    <AppModal
      open={rollupConfirmOpen}
      onClose={handleRollupConfirmClose}
      title={t(
        rollupDeployState.grade === GRADE.mainnet
          ? keys.deployRollupConfirmModal.mainnetTitle
          : keys.deployRollupConfirmModal.testnetTitle,
      )}
      titleClassName={classes.confirmModalTitle}
      subtitle={t(keys.deployRollupConfirmModal.subtitle)}
      classes={{ paper: classes.modalContent }}
    >
      {ALLOW_CRYPTO_PAYMENTS && !!hasPrice && (
        <PaymentTypeSwitcher
          paymentType={paymentType}
          handleSwitch={setPaymentType}
          className={classes.paymentType}
        />
      )}

      <Box className={classes.listBody}>
        {currentStack && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.stack)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <StackDALIcon
                  keyString={currentStack.key}
                  title={currentStack.title}
                  className={classes.plansModalImg}
                />
                {currentStack.title}
              </Box>
            </Box>
          </Box>
        )}

        {rollupDeployState.chainId && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.chainId)}
            </Box>
            <Box className={classes.listRowRight}>
              {rollupDeployState.chainId}
            </Box>
          </Box>
        )}

        {rollupDeployState.networkName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.networkName)}
            </Box>
            <Box className={classes.listRowRight}>
              {rollupDeployState.networkName}
            </Box>
          </Box>
        )}

        {rollupDeployState.grade && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.grade)}
            </Box>
            <Box className={classes.listRowRight}>
              <GradeChip grade={rollupDeployState.grade} />
            </Box>
          </Box>
        )}

        {currentDAL && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.DAL)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <StackDALIcon
                  keyString={currentDAL.key}
                  title={currentDAL.title}
                  className={classes.plansModalImg}
                  isDAL
                />
                {currentDAL.title}
              </Box>
            </Box>
          </Box>
        )}

        {rollupDeployState.sequencerAddress && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.sequencerAddress)}
            </Box>
            <Box className={classes.listRowRight}>
              {truncateWalletAddress(rollupDeployState.sequencerAddress)}
            </Box>
          </Box>
        )}

        {rollupDeployState.sequencerUrl && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.sequencerUrl)}
            </Box>
            <Box className={classes.listRowRight}>
              {rollupDeployState.sequencerUrl}
            </Box>
          </Box>
        )}

        {gasTokenLogo && gasTokenName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.deployRollupConfirmModal.gasToken)}
            </Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={gasTokenLogo}
                  alt={gasTokenName}
                  className={classes.plansModalImg}
                />
                {gasTokenName}
              </Box>
            </Box>
          </Box>
        )}

        {currentRPCAmount && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{currentRPCAmount.title}</Box>
            <Box className={classes.listRowRight}>{currentRPCAmount.value}</Box>
          </Box>
        )}
      </Box>

      {!isManualDeployment.isManual &&
        ((rollupDeployState.grade === GRADE.mainnet &&
          rollupDeployState.sequencerManagementType ===
            SEQUENCER_MANAGEMENT_TYPE.product) ||
          rollupDeployState.gasToken === DECLARED_TOKEN.custom) && (
          <Box mt={4}>
            <Alert severity="info" sx={{ width: '100%' }}>
              {t(keys.deployRollupConfirmModal.depositHint, {
                amount: depositAmount,
              })}
            </Alert>
          </Box>
        )}

      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        {isManualDeployment.isManual ? (
          <LoadingButton
            onClick={handleConfirmManual}
            loading={isPostRollupManualDeploymentLoading}
            size="large"
            fullWidth
          >
            {t(keys.deployRollupConfirmModal.confirmManual)}
            <ExternalLink />
          </LoadingButton>
        ) : (
          <LoadingButton
            onClick={handleConfirm}
            loading={isPostRollupDeploymentLoading}
            size="large"
            fullWidth
          >
            {hasPrice
              ? t(keys.deployRollupConfirmModal.confirmAndPay, {
                  price: tCommon('number.price', {
                    value: currentPlan?.price,
                  }),
                })
              : t(keys.deployRollupConfirmModal.confirm)}
          </LoadingButton>
        )}
        <Button
          onClick={handleRollupConfirmClose}
          disabled={isPostRollupDeploymentLoading}
          size="large"
          fullWidth
          variant="outlined"
        >
          {t(keys.deployRollupConfirmModal.cancel)}
        </Button>

        {!!hasPrice && !isManualDeployment.isManual && (
          <>
            <Typography
              variant="body3"
              className={classes.textSecondary}
              component={Box}
              textAlign="center"
            >
              {t(
                keys.deployRollupConfirmModal.terms,
                {
                  docUrl: TERMS_OF_USE_URL,
                },
                true,
              )}
            </Typography>
            <PaymentPoweredBy
              paymentType={paymentType}
              className={classes.poweredBy}
            />
          </>
        )}
      </Box>
    </AppModal>
  );
}
