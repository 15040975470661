import { useMemo } from 'react';

import { featuresConfig } from '../../../../common/const/features';
import { useGetCurrentDAL } from '../../../../common/hooks/useGetCurrentDAL';
import { useGetCurrentStack } from '../../../../common/hooks/useGetCurrentStack';
import { SEQUENCER_MANAGEMENT_TYPE } from '../../../rollupConst';
import { useRollupDeployState } from './useRollupDeployState';

interface IUseIsManualDeploymentRes {
  isManualStack: boolean;
  isManualDAL: boolean;
  isManualSequencer: boolean;
  isManualToken: boolean;
  isManual: boolean;
}

export const useIsManualDeployment = (): IUseIsManualDeploymentRes => {
  const rollupDeployState = useRollupDeployState();

  const currentStack = useGetCurrentStack({
    stackUuid: rollupDeployState.stack,
  });
  const currentDAL = useGetCurrentDAL({
    DALUuid: rollupDeployState.dataAvailabilityLayer,
    stackUuid: rollupDeployState.stack,
    grade: rollupDeployState.grade,
  });

  const isManualStack = useMemo(
    () => currentStack?.comingSoon || false,
    [currentStack?.comingSoon],
  );
  const isManualDAL = useMemo(
    () => currentDAL?.comingSoon || false,
    [currentDAL?.comingSoon],
  );
  const isManualSequencer = useMemo(
    () =>
      !featuresConfig.selfSequencerManageAvailable &&
      rollupDeployState.sequencerManagementType ===
        SEQUENCER_MANAGEMENT_TYPE.self,
    [rollupDeployState.sequencerManagementType],
  );
  const isManualToken = useMemo(
    () =>
      currentDAL?.gasTokens?.find(
        gasToken => gasToken.key === rollupDeployState.gasToken,
      )?.comingSoon || false,
    [currentDAL?.gasTokens, rollupDeployState.gasToken],
  );
  const isManual = useMemo(
    () => isManualStack || isManualDAL || isManualSequencer || isManualToken,
    [isManualDAL, isManualSequencer, isManualStack, isManualToken],
  );

  return {
    isManualStack,
    isManualDAL,
    isManualSequencer,
    isManualToken,
    isManual,
  };
};
