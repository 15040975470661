import { createGateways } from '@ankr.com/auth';

import { API_BASE_URL, CMS_API_BASE_URL } from './const';

export const Gateways: ReturnType<typeof createGateways> = createGateways({
  baseURL: API_BASE_URL,
});

export const CMSGateways: ReturnType<typeof createGateways> = createGateways({
  baseURL: CMS_API_BASE_URL,
});
