import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore } from 'redux-persist';

import { mainApi } from '../modules/api';
import { cmsApi } from '../modules/api/cmsApi';
import { i18nReducer } from '../modules/i18n';
import { rollupDeploySlice } from '../modules/Rollup/screens/DeployRollup/store/rollupDeploySlice';
import { rollupDeployStepsSlice } from '../modules/Rollup/screens/DeployRollup/store/rollupDeployStepsSlice';

const rootReducer = combineReducers({
  i18n: i18nReducer,
  rollupDeploy: rollupDeploySlice.reducer,
  rollupDeploySteps: rollupDeployStepsSlice.reducer,
  [mainApi.reducerPath]: mainApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // TODO: fix serializable error
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: false,
    }).concat([mainApi.middleware, cmsApi.middleware]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
