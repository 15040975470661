import { utils } from 'web3';

import { featuresConfig } from '../../../../common/const/features';
import {
  GRADE,
  IDeployRollupFormApi,
  IDeployRollupFormPayload,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../../rollupConst';
import { mapExplorerSettingsToApi } from '../../../utils/mapExplorerSettingsToApi';

export function mapRollupFormForApi(
  payload: IDeployRollupFormPayload,
): IDeployRollupFormApi {
  return {
    plan_uuid: payload.planUuid,

    data_availability_layer_uuid: payload.dataAvailabilityLayer,

    sequencer_management_type:
      payload.grade === GRADE.testnet
        ? undefined
        : payload.sequencerManagementType,

    sequencer_address:
      payload.grade === GRADE.testnet ||
      payload.sequencerManagementType === SEQUENCER_MANAGEMENT_TYPE.product
        ? undefined
        : payload.sequencerAddress?.trim(),

    sequencer_url:
      payload.grade === GRADE.testnet ||
      payload.sequencerManagementType === SEQUENCER_MANAGEMENT_TYPE.product ||
      !featuresConfig.selfSequencerManageAvailable
        ? undefined
        : payload.sequencerUrl?.trim(),

    chain_id: parseInt(payload.chainId as string, 10),

    network_name: payload.networkName?.trim(),

    gas_token: payload.gasToken,

    gas_token_address: payload.gasTokenAddress
      ? utils.toChecksumAddress(payload.gasTokenAddress?.trim())
      : undefined,

    optional_settings: !payload.optionalSettings?.length
      ? undefined
      : payload.optionalSettings,

    signature: payload.signature,

    user_address: payload.userAddress?.trim(),

    explorer_settings: payload.explorerSettings
      ? mapExplorerSettingsToApi(payload.explorerSettings)
      : undefined,

    payment_type: payload.paymentType,
  };
}
