import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../../../api';
import { mapAccountInfoFromApi } from '../utils/mapAccountInfoFromApi';
import { IAccountInfo, IAccountInfoFormApi } from './types';

export const { useGetAccountInfoQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getAccountInfo: build.query<IAccountInfo, void>({
      queryFn: queryFnNotifyWrapper<void, never, IAccountInfo>(async () => {
        const { data }: { data: IAccountInfoFormApi } =
          await Gateways.auth.api.get('/api/v1/user/details');

        return { data: mapAccountInfoFromApi(data) };
      }),
      providesTags: [cacheTags.accountInfo],
    }),
  }),
});
