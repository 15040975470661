import { Locale } from '../../../i18n';

export const planCancellationModalTranslation = {
  [Locale.en]: {
    title: 'Are You Sure<br /> You Want to Cancel?',
    titleSuccess: 'Success',
    titleFailed: 'Cancellation Failed',
    hint: 'We’re sorry to see you go! Your subscription will remain active until {paidUntilDate}, but no further charges will be made. Canceling now means you’ll lose access to infrastructure and support after this date.',
    hintSuccess: 'Your cancellation has been received.',
    hintFailed:
      'Oops, something went wrong. Your cancellation has not been received. Please try again or contact our team...',
    keep: 'Keep My Subscription',
    confirm: 'Confirm Cancellation',
    contactHint:
      'Need help or have questions? <a href="{contactUrl}" target="_blank" rel="noreferrer">Contact our support team</a> - we’re here to assist you!',
    contactHintFailed:
      'Need help or have questions? <a href="{contactUrl}" target="_blank" rel="noreferrer">Contact our team</a>',
    gotToDashboard: 'Go To Dashboard',
    tryAgain: 'Try Again',
    cancel: 'Cancel',

    titleManual: 'Are you sure?',
    hintManual:
      'If you are sure you want to cancel your plan, please contact our Sales team below with your request.',
    contact: 'Contact Sales',
    close: 'Close',
  },
};
