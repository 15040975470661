import { makeStyles } from 'tss-react/mui';

export const usePaymentPoweredByStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  img: {
    minHeight: 21,
    verticalAlign: 'middle',
  },
}));
