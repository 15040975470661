import React, { JSX, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppModal, ImageX } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Close, LoadingButton } from '@ankr.com/ui';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { DialogId } from '../../../common/actions/openDialog';
import successUrl from '../../../common/assets/success.png';
import successUrl2x from '../../../common/assets/success@2x.png';
import imgUrl from '../../../common/assets/warning.png';
import imgUrl2x from '../../../common/assets/warning@2x.png';
import { featuresConfig } from '../../../common/const/features';
import { HUBSPOT_URL } from '../../../common/const/values';
import { useDialog } from '../../../common/hooks/useDialog';
import { DashboardRoutesConfig } from '../../../Dashboard/DashboardRoutes';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import { useCancelSubscriptionMutation } from './actions/cancelSubscription';
import { planCancellationModalTranslation } from './translation';
import { usePlanCancellationModalStyles } from './usePlanCancellationModalStyles';

interface IPlanCancellationModalProps {
  rollupUuid: string;
  paidUntil?: Date;
}

export function PlanCancellationModal({
  rollupUuid,
  paidUntil,
}: IPlanCancellationModalProps): JSX.Element {
  const { classes } = usePlanCancellationModalStyles();

  const { keys, t } = useTranslation(planCancellationModalTranslation);

  const [
    cancelSubscription,
    {
      isLoading: isCancellationLoading,
      isSuccess: isCancellationSuccess,
      isError: isCancellationError,
    },
  ] = useCancelSubscriptionMutation({ fixedCacheKey: rollupUuid });

  const onSubmitClick = useCallback(async () => {
    await cancelSubscription({ uuid: rollupUuid });
  }, [cancelSubscription, rollupUuid]);

  const { open: planCancelModalOpen, handleClose: handlePlanCancelModalClose } =
    useDialog(DialogId.PlanCancel);

  const navigate = useNavigate();

  const handleGoToDashboardClick = useCallback(() => {
    handlePlanCancelModalClose();
    navigate(DashboardRoutesConfig.Dashboard.generatePath());
  }, [handlePlanCancelModalClose, navigate]);

  const renderedModalContent = useLocaleMemo(() => {
    if (isCancellationError) {
      return (
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handlePlanCancelModalClose}
            color="inherit"
            className={classes.modalCloseBtn}
          >
            <Close sx={{ color: 'inherit' }} />
          </IconButton>

          <Box className={classes.textWrap}>
            <Typography variant="h2" className={classes.modalTitle}>
              {t(keys.titleFailed)}
            </Typography>
            <Box className={classes.modalHintFailed}>
              <Typography variant="body2" className={classes.modalHint}>
                {t(keys.hintFailed)}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.modalButtons}>
            <LoadingButton
              onClick={onSubmitClick}
              loading={isCancellationLoading}
              size="large"
              fullWidth
            >
              {t(keys.tryAgain)}
            </LoadingButton>
            <Button
              size="large"
              variant="outlined"
              onClick={handlePlanCancelModalClose}
              disabled={isCancellationLoading}
            >
              {t(keys.cancel)}
            </Button>
          </Box>

          <Typography
            variant="body2"
            className={classes.modalContactHint}
            component={Box}
          >
            {t(keys.contactHintFailed, { contactUrl: HUBSPOT_URL }, true)}
          </Typography>
        </Box>
      );
    }

    if (isCancellationSuccess) {
      return (
        <Box className={classes.modalContent}>
          <IconButton
            aria-label="close"
            onClick={handlePlanCancelModalClose}
            color="inherit"
            className={classes.modalCloseBtn}
          >
            <Close sx={{ color: 'inherit' }} />
          </IconButton>

          <ImageX
            imgUrl={successUrl}
            img2xUrl={successUrl2x}
            className={classes.modalSuccessImage}
          />

          <Box className={classes.textWrap}>
            <Typography variant="h2" className={classes.modalTitle}>
              {t(keys.titleSuccess)}
            </Typography>
            <Typography variant="body2" className={classes.modalHint}>
              {t(keys.hintSuccess)}
            </Typography>
          </Box>
          <Box className={classes.modalButtons}>
            <Button size="large" onClick={handleGoToDashboardClick}>
              {t(keys.gotToDashboard)}
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Box className={classes.modalContent}>
        <ImageX
          imgUrl={imgUrl}
          img2xUrl={imgUrl2x}
          className={classes.modalImage}
        />

        <Box>
          <Typography variant="h2" className={classes.modalTitle}>
            {t(keys.title, {}, true)}
          </Typography>
          <Typography variant="body2" className={classes.modalHint}>
            {t(keys.hint, {
              paidUntilDate: tCommon('datetime.date-long', {
                value: paidUntil,
              }),
            })}
          </Typography>
        </Box>

        <Box className={classes.modalButtons}>
          <Button
            size="large"
            onClick={handlePlanCancelModalClose}
            disabled={isCancellationLoading}
          >
            {t(keys.keep)}
          </Button>
          <LoadingButton
            onClick={onSubmitClick}
            loading={isCancellationLoading}
            size="large"
            variant="outlined"
            fullWidth
          >
            {t(keys.confirm)}
          </LoadingButton>
        </Box>
        <Typography
          variant="body2"
          className={classes.modalContactHint}
          component={Box}
        >
          {t(keys.contactHint, { contactUrl: HUBSPOT_URL }, true)}
        </Typography>
      </Box>
    );
  }, [
    classes,
    handlePlanCancelModalClose,
    isCancellationError,
    isCancellationLoading,
    isCancellationSuccess,
    keys,
    onSubmitClick,
    paidUntil,
    t,
  ]);

  return featuresConfig.enableAutoPlanCancellation ? (
    <AppModal
      open={planCancelModalOpen}
      onClose={isCancellationLoading ? undefined : handlePlanCancelModalClose}
      maxWidth="xs"
      titleCentered
      withoutCloseButton
      classes={{ paper: classes.modal }}
    >
      {renderedModalContent}
    </AppModal>
  ) : (
    <AppModal
      open={planCancelModalOpen}
      onClose={handlePlanCancelModalClose}
      title={t(keys.titleManual)}
      maxWidth="xs"
      titleCentered
      withoutCloseButton
      titleClassName={classes.modalTitle}
      classes={{ paper: classes.modal }}
    >
      <Typography
        variant="body2"
        className={classes.modalHint}
        textAlign="center"
        component={Box}
      >
        {t(keys.hintManual)}
      </Typography>
      <Box className={classes.modalButtons}>
        <Button
          size="large"
          href={HUBSPOT_URL}
          rel="noreferrer"
          target="_blank"
        >
          {t(keys.contact)}
        </Button>
        <Button
          onClick={handlePlanCancelModalClose}
          size="large"
          variant="outlined"
          fullWidth
        >
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
