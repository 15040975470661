import React, { JSX } from 'react';
import QRCode from 'react-qr-code';
import { AppModal, CopyButton } from '@ankr.com/raas-ui';
import { tCommon, truncateWalletAddress } from '@ankr.com/raas-utils';
import { Alert, Box, Button } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { IDepositItem } from '../../../../rollupConst';
import { rollupDepositModalTranslation } from './translation';
import { useRollupDepositStyles } from './useRollupDepositStyles';

interface IRollupDepositModalProps {
  deposits?: IDepositItem;
  l1ChainTokenLogo?: string;
  l1ChainTokenName?: string;
}

export function RollupDepositModal({
  deposits,
  l1ChainTokenLogo,
  l1ChainTokenName,
}: IRollupDepositModalProps): JSX.Element {
  const { classes } = useRollupDepositStyles();

  const { keys, t } = useTranslation(rollupDepositModalTranslation);

  const {
    open: rollupDepositModalOpen,
    handleClose: handleRollupDepositModalClose,
  } = useDialog(DialogId.RollupDeposit);

  return (
    <AppModal
      open={rollupDepositModalOpen}
      onClose={handleRollupDepositModalClose}
      title={t(keys.title)}
      subtitle={t(keys.subtitle, {
        amount: deposits?.parsedForUI.amountsWithToken?.join(
          tCommon('common.plus-sign-spaced'),
        ),
      })}
      classes={{ paper: classes.modalContent }}
    >
      <Box className={classes.listBody}>
        {!!l1ChainTokenLogo && !!l1ChainTokenName && (
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.network)}</Box>
            <Box className={classes.listRowRight}>
              <Box display="flex" gap={2} alignItems="center">
                <img
                  src={l1ChainTokenLogo}
                  alt={l1ChainTokenName}
                  className={classes.logoImg}
                />
                {l1ChainTokenName}
              </Box>
            </Box>
          </Box>
        )}

        {!!deposits &&
          deposits.fundRequests.map(fundRequest => (
            <Box
              key={`fundRequest_${fundRequest.token}${fundRequest.amount.toString()}${fundRequest.address ?? ''}`}
              className={classes.listRow}
            >
              <Box className={classes.listRowLeft}>
                {t(keys.amount, { token: fundRequest.token })}
              </Box>
              <Box className={classes.listRowRight}>
                {t(keys.currencyAmount, {
                  ethDeposit: fundRequest.amount,
                  token: fundRequest.token,
                })}
              </Box>
            </Box>
          ))}
        {!!deposits?.parsedForUI.address && (
          <>
            <Box className={classes.listRow}>
              <Box className={classes.listRowLeft}>{t(keys.address)}</Box>
              <Box className={classes.listRowRight}>
                <Box display="flex" gap={2} alignItems="center">
                  {truncateWalletAddress(deposits.parsedForUI.address)}

                  <CopyButton
                    text={deposits.parsedForUI.address}
                    size="extraSmall"
                    className={classes.smallFlatBtn}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.listRow}>
              <Box className={classes.qrCodeWrap}>
                <QRCode
                  value={deposits.parsedForUI.address}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Alert severity="info" className={classes.alertBlock}>
        {t(keys.alert)}
      </Alert>

      <Box display="flex" flexDirection="column" gap={3} mt={8}>
        {!!deposits?.parsedForUI.address && (
          <CopyButton
            text={deposits?.parsedForUI.address}
            tooltipPlacement="top"
            renderButton={isCopied => (
              <Button
                size="large"
                fullWidth
                color={isCopied ? 'success' : 'primary'}
              >
                {isCopied ? t(keys.copied) : t(keys.copyAddress)}
              </Button>
            )}
          />
        )}

        <Button
          onClick={handleRollupDepositModalClose}
          size="large"
          variant="outlined"
          fullWidth
        >
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
