import React, { JSX } from 'react';
import { QueryEmpty } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Check, Chip, Gear } from '@ankr.com/ui';
import { Box, Button, Card, Grid, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import {
  ALLOW_BLOCKEXPLORER_CUSTOMIZATION,
  ROLLUP_TESTNET_DURATION,
} from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useGetCurrentPlan } from '../../../../../common/hooks/useGetCurrentPlan';
import { useTranslation } from '../../../../../i18n';
import { useGetPlansQuery } from '../../actions/getPlans';
import { useIsManualDeployment } from '../../hooks/useIsManualDeployment';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupConfirmModal } from '../DeployRollupConfirmModal';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';
import { DeployRollupTestnetProveModal } from '../DeployRollupTestnetProveModal';
import { ExplorerSettingsModal } from '../ExplorerSettingsModal';

export function DeployRollupFormInfraPackage(): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { handleOpen: handleRollupPlansOpen } = useDialog(DialogId.RollupPlans);

  const { handleOpen: handleExplorerSettingsOpen } = useDialog(
    DialogId.ExplorerSettings,
  );

  const rollupDeployState = useRollupDeployState();

  const { data: plans } = useGetPlansQuery(
    {
      grade: rollupDeployState.grade,
      stack: rollupDeployState.stack,
    },
    {
      skip: !rollupDeployState.grade || !rollupDeployState.stack,
    },
  );

  const currentPlan = useGetCurrentPlan({
    planUuid: rollupDeployState.planUuid,
    stackUuid: rollupDeployState.stack,
    grade: rollupDeployState.grade,
  });

  const isManualDeployment = useIsManualDeployment();

  return (
    <>
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.titleWrap}>
            <Box display="flex" gap={2}>
              <Typography
                className={classes.sectionTitle}
                variant="h6"
                component="div"
              >
                {currentPlan?.title}
              </Typography>
              {!isManualDeployment.isManual && (
                <Box>
                  {currentPlan?.price && !currentPlan?.price.isZero() ? (
                    <Chip
                      label={t(
                        'number.price-per-month',
                        { value: currentPlan?.price },
                        true,
                      )}
                      color="primary"
                    />
                  ) : (
                    <Chip
                      label={t(keys.freeTrial, {
                        hours: ROLLUP_TESTNET_DURATION,
                      })}
                      color="secondary"
                    />
                  )}
                </Box>
              )}
            </Box>

            {!isManualDeployment.isManual && plans && plans?.length > 1 && (
              <Button onClick={handleRollupPlansOpen}>
                {t(keys.deployRollupInfra.changePlan)}
              </Button>
            )}
          </Box>

          {!currentPlan?.settings?.length ? (
            <QueryEmpty />
          ) : (
            <Grid container rowSpacing={5} columnSpacing={7}>
              {currentPlan.settings.map(settingItem => {
                const titleString =
                  typeof settingItem.value === 'boolean'
                    ? settingItem.title
                    : tCommon('common.two-spaced', {
                        first: settingItem.value,
                        second: settingItem.title,
                      });

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`settingItem_${settingItem.uuid}`}
                  >
                    <Box display="flex" gap={1}>
                      <Check color="success" />
                      <Box>
                        <Typography variant="subtitle2" component="div" mb={1}>
                          {titleString}
                        </Typography>
                        <Typography
                          variant="body3"
                          component="div"
                          className={classes.sectionSubtitle}
                        >
                          {settingItem.description}
                        </Typography>
                        {ALLOW_BLOCKEXPLORER_CUSTOMIZATION &&
                          settingItem.key === 'block_explorer' && (
                            <Box mt={2}>
                              <Button
                                onClick={handleExplorerSettingsOpen}
                                variant="outlined"
                                size="extraSmall"
                                startIcon={<Gear />}
                              >
                                {t(keys.deployRollupInfra.explorerSettingsBtn)}
                              </Button>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      </Card>

      <DeployRollupControlPanel />

      {ALLOW_BLOCKEXPLORER_CUSTOMIZATION &&
        currentPlan?.settings?.some(
          settingItem => settingItem.key === 'block_explorer',
        ) && <ExplorerSettingsModal />}

      <DeployRollupConfirmModal
        currentPlan={currentPlan}
        rollupDeployState={rollupDeployState}
      />

      {!isManualDeployment.isManual &&
        (!currentPlan?.price || currentPlan?.price.isZero()) && (
          <DeployRollupTestnetProveModal />
        )}
    </>
  );
}
