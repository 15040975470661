import React, { JSX, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumberFormatField } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import {
  CancelledIcon,
  ExternalLink,
  OverlaySpinner,
  Question,
  Success,
  TextField,
} from '@ankr.com/ui';
import {
  Alert,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  CUSTOM_GAS_TOKEN_DOCS_URL,
  MAX_CHAIN_ID,
  PROJECT_TITLE,
} from '../../../../../common/const/values';
import { useGetCurrentDAL } from '../../../../../common/hooks/useGetCurrentDAL';
import { useGetL1ChainByStack } from '../../../../../common/hooks/useGetL1ChainByStack';
import { getChain } from '../../../../../common/utils/getChain';
import { getToken } from '../../../../../common/utils/getToken';
import { useLocale, useLocaleMemo, useTranslation } from '../../../../../i18n';
import {
  DECLARED_TOKEN,
  deployRollupInitialState,
  GRADE,
  IDeployRollupFormConfigurationPayload,
} from '../../../../rollupConst';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useIsManualDeployment } from '../../hooks/useIsManualDeployment';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';
import { ManualChip } from '../ManualChip';
import { useConfigurationStepValidation } from './hooks/useConfigurationStepValidation';

export function DeployRollupFormConfiguration(): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { locale } = useLocale();

  const rollupDeployState = useRollupDeployState();

  const currentDAL = useGetCurrentDAL({
    DALUuid: rollupDeployState.dataAvailabilityLayer,
    stackUuid: rollupDeployState.stack,
    grade: rollupDeployState.grade,
  });

  const { dispatchData } = useDispatchRollupDeployData();

  const { control, handleSubmit, getValues, watch, setValue } =
    useForm<IDeployRollupFormConfigurationPayload>({
      defaultValues: {
        chainId: rollupDeployState.chainId,
        networkName: rollupDeployState.networkName,
        gasToken: rollupDeployState.gasToken,
        gasTokenAddress: rollupDeployState.gasTokenAddress,
        optionalSettings: rollupDeployState.optionalSettings,
      },
      mode: 'onChange',
    });

  const isMainnet = useMemo(
    () => rollupDeployState.grade === GRADE.mainnet,
    [rollupDeployState.grade],
  );

  const l1Chain = useGetL1ChainByStack({
    stackUuid: rollupDeployState.stack,
    isMainnet,
  });

  // TODO: need to get l1chain from the backend by selected grade and stack or DAL
  const chain = useMemo(() => {
    return getChain({
      value: l1Chain,
    }).name;
  }, [l1Chain]);

  const isCustomGasTokenSelected = watch('gasToken') === DECLARED_TOKEN.custom;

  /**
   * Reset gasToken if isn't in list
   */
  useEffect(() => {
    if (
      !currentDAL?.gasTokens?.some(
        gasToken => gasToken.key === rollupDeployState.gasToken,
      )
    ) {
      setValue('gasToken', deployRollupInitialState.gasToken);
      dispatchData({
        gasToken: deployRollupInitialState.gasToken,
      });
    }
  }, [
    currentDAL?.gasTokens,
    dispatchData,
    rollupDeployState.dataAvailabilityLayer,
    rollupDeployState.gasToken,
    setValue,
  ]);

  /**
   * Reset gasTokenAddress if custom gas token unselected
   */
  useEffect(() => {
    if (!isCustomGasTokenSelected) {
      setValue('gasTokenAddress', deployRollupInitialState.gasTokenAddress);
      dispatchData({
        gasTokenAddress: deployRollupInitialState.gasTokenAddress,
      });
    }
  }, [dispatchData, isCustomGasTokenSelected, setValue]);

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
  };

  const onSubmit = useCallback(
    (payload: IDeployRollupFormConfigurationPayload) => {
      dispatchData(payload, true);
    },
    [dispatchData],
  );

  const isManualDeployment = useIsManualDeployment();

  const {
    isChainIDUsedFetching,
    validateChainID,
    validateAddress,
    isAddressValidationFetching,
    gasTokenAddressValidation,
  } = useConfigurationStepValidation();

  const gasTokenAddressValidationLevels = useLocaleMemo(() => {
    const getStatusIcon = (status: boolean | undefined) => {
      if (status) {
        return (
          <Success
            className={cx(
              classes.customGasTokenValLevelIcon,
              classes.customGasTokenValLevelIconSuccess,
            )}
          />
        );
      }
      if (status === false) {
        return (
          <CancelledIcon
            className={cx(
              classes.customGasTokenValLevelIcon,
              classes.customGasTokenValLevelIconError,
            )}
          />
        );
      }
      return <CancelledIcon className={classes.customGasTokenValLevelIcon} />;
    };

    return (
      <Stack direction="column" justifyContent="flex-start" spacing={2} mt={2}>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.isValidAddress)}
          {t(keys.deployRollupConfigurationToken.isValidAddress)}
        </Typography>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.isErc20)}
          {t(keys.deployRollupConfigurationToken.isErc20)}
        </Typography>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.is18Decimals)}
          {t(keys.deployRollupConfigurationToken.is18Decimals)}
        </Typography>
        <Typography
          variant="body3"
          component="div"
          className={classes.customGasTokenValLevel}
        >
          {getStatusIcon(gasTokenAddressValidation?.isNonRebasing)}
          {t(keys.deployRollupConfigurationToken.isNonRebasing)}
        </Typography>
      </Stack>
    );
  }, [
    classes.customGasTokenValLevel,
    classes.customGasTokenValLevelIcon,
    classes.customGasTokenValLevelIconError,
    classes.customGasTokenValLevelIconSuccess,
    cx,
    gasTokenAddressValidation?.is18Decimals,
    gasTokenAddressValidation?.isErc20,
    gasTokenAddressValidation?.isNonRebasing,
    gasTokenAddressValidation?.isValidAddress,
    keys.deployRollupConfigurationToken.is18Decimals,
    keys.deployRollupConfigurationToken.isErc20,
    keys.deployRollupConfigurationToken.isNonRebasing,
    keys.deployRollupConfigurationToken.isValidAddress,
    t,
  ]);

  const hasManual = useMemo(
    () =>
      isManualDeployment.isManualStack ||
      isManualDeployment.isManualDAL ||
      isManualDeployment.isManualSequencer,
    [
      isManualDeployment.isManualDAL,
      isManualDeployment.isManualSequencer,
      isManualDeployment.isManualStack,
    ],
  );

  const hasAnkrETHGasToken = useMemo(
    () =>
      currentDAL?.gasTokens?.some(
        gasToken => gasToken.key === DECLARED_TOKEN.ankreth,
      ),
    [currentDAL?.gasTokens],
  );

  const renderedGasTokenList = useLocaleMemo(() => {
    return currentDAL?.gasTokens?.map(gasToken => {
      const { image, name } = getToken({
        value: gasToken.key,
        imageClassName: classes.gasTokenIcon,
      });
      if (
        gasToken.key === DECLARED_TOKEN.custom ||
        gasToken.key === DECLARED_TOKEN.new
      ) {
        return {
          label: (
            <Box>
              <Box display="flex" gap={1.5}>
                {name}
                {!!gasToken.comingSoon && !hasManual && (
                  <ManualChip
                    label={t(keys.manual)}
                    tooltip={t(keys.manualHint)}
                    chipClassName={classes.hoverHighlightChip}
                    tooltipPlacement="right"
                  />
                )}
              </Box>
              {gasToken.key === DECLARED_TOKEN.custom && !hasManual && (
                <Typography
                  variant="body3"
                  className={classes.textSecondary}
                  component="div"
                >
                  {t(keys.deployRollupConfigurationProps.customGasTokenHint)}
                </Typography>
              )}
            </Box>
          ),
          key: gasToken.key,
        };
      }

      return {
        label: (
          <Box className={classes.tokenWrap}>
            {image}
            {name}
            {!!gasToken.comingSoon && !hasManual && (
              <ManualChip
                label={t(keys.manual)}
                tooltip={t(keys.manualHint)}
                chipClassName={classes.hoverHighlightChip}
                tooltipPlacement="right"
              />
            )}
          </Box>
        ),
        key: gasToken.key,
      };
    });
  }, [
    classes.gasTokenIcon,
    classes.hoverHighlightChip,
    classes.textSecondary,
    classes.tokenWrap,
    currentDAL?.gasTokens,
    hasManual,
    keys.deployRollupConfigurationProps.customGasTokenHint,
    keys.manual,
    keys.manualHint,
    t,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleFormChange}
      noValidate
      autoComplete="off"
    >
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <Box className={classes.section}>
          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="chainId"
              rules={{
                required: tCommon('validation.required'),
                validate: v => validateChainID(Number(v)),
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberFormatField
                  {...field}
                  label={
                    <Box className={classes.inputLabelWrap}>
                      {t(keys.deployRollupConfigurationProps.chainId)}
                      <Tooltip
                        title={t(
                          keys.deployRollupConfigurationProps.chainIdTooltip,
                        )}
                      >
                        <Question className={classes.questionIcon} />
                      </Tooltip>
                    </Box>
                  }
                  helperText={t(
                    keys.deployRollupConfigurationProps.chainIdHint,
                  )}
                  fullWidth
                  autoFocus
                  allowNegative={false}
                  thousandSeparator=""
                  decimalScale={0}
                  endLabel={
                    isChainIDUsedFetching && (
                      <OverlaySpinner
                        size={20}
                        classes={{
                          root: classes.validationSpinner,
                        }}
                      />
                    )
                  }
                  locale={locale}
                  minValue={0}
                  maxValue={MAX_CHAIN_ID}
                  inputMode="numeric"
                  errorText={error?.message}
                />
              )}
            />
          </Box>
          <Box className={classes.sectionHalfWrap}>
            <Controller
              name="networkName"
              rules={{
                required: tCommon('validation.required'),
              }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  helperText={error ? error.message : null}
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  type="url"
                  label={t(keys.deployRollupConfigurationProps.networkName)}
                />
              )}
            />
          </Box>
        </Box>

        {!!renderedGasTokenList?.length && (
          <Box className={classes.section}>
            <Box className={classes.sectionTitleWrap}>
              {isMainnet && hasAnkrETHGasToken && (
                <Alert severity="info" className={classes.alertBlock}>
                  {t(keys.deployRollupConfigurationToken.tokensHint, {
                    project: PROJECT_TITLE,
                  })}
                </Alert>
              )}

              <Typography
                className={classes.sectionTitle}
                variant="subtitle1"
                component="div"
              >
                {t(keys.deployRollupConfigurationToken.title)}
              </Typography>
              <Typography
                className={classes.sectionSubtitle}
                variant="body3"
                component="div"
              >
                {isMainnet &&
                  hasAnkrETHGasToken &&
                  t(keys.deployRollupConfigurationToken.subtitleMainnet)}
              </Typography>
            </Box>

            <Box className={classes.sectionHalfWrap}>
              <Controller
                name="gasToken"
                rules={{
                  required: tCommon('validation.required-one'),
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <RadioGroup
                    name="gasToken"
                    className={classes.radioGroup}
                    value={field.value}
                  >
                    {renderedGasTokenList.map(item => {
                      if (!item) {
                        return null;
                      }

                      return (
                        <FormControlLabel
                          key={`gasToken_${item.key}`}
                          control={
                            <Radio
                              {...field}
                              size="small"
                              value={item.key}
                              disableFocusRipple
                            />
                          }
                          label={item.label}
                        />
                      );
                    })}

                    {!!fieldState.error?.message && (
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    )}
                  </RadioGroup>
                )}
              />
            </Box>

            {isCustomGasTokenSelected && !hasManual && (
              <Box className={classes.sectionHalfWrap} mt={6}>
                <Controller
                  name="gasTokenAddress"
                  rules={{
                    validate: v => validateAddress(v),
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      helperText={
                        <Box
                          component="span"
                          className={classes.customTextFieldHint}
                        >
                          {t(
                            keys.deployRollupConfigurationToken
                              .customGasTokenHint,
                            { chain },
                            true,
                          )}
                        </Box>
                      }
                      error={!!error}
                      onChange={onChange}
                      endLabel={
                        isAddressValidationFetching && (
                          <OverlaySpinner
                            size={20}
                            classes={{
                              root: classes.validationSpinner,
                            }}
                          />
                        )
                      }
                      value={value}
                      fullWidth
                      label={t(
                        keys.deployRollupConfigurationProps
                          .customGasTokenAddress,
                      )}
                    />
                  )}
                />
                {gasTokenAddressValidationLevels}
              </Box>
            )}

            {!!CUSTOM_GAS_TOKEN_DOCS_URL &&
              currentDAL?.gasTokens?.some(
                gasToken => gasToken.key === DECLARED_TOKEN.custom,
              ) && (
                <Typography
                  className={classes.sectionSubtitle}
                  variant="body3"
                  component="div"
                  mt={6}
                >
                  {t(keys.deployRollupConfigurationToken.wantAddTokenHint)}
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    endIcon={<ExternalLink />}
                    href={CUSTOM_GAS_TOKEN_DOCS_URL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t(keys.deployRollup.viewRequirements)}
                  </Button>
                </Typography>
              )}
          </Box>
        )}
      </Card>

      <DeployRollupControlPanel />
    </form>
  );
}
