import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { Gateways, mainApi } from '../../../../api';
import { PROJECT_KEY } from '../../../../common/const/values';
import { IStackItem } from '../../../rollupConst';
import { mapStacksFromApi } from '../utils/mapStacksFromApi';

export const { useGetStacksQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getStacks: build.query<IStackItem[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IStackItem[]>(async () => {
        // TODO: temporary fix, waiting for backend updates
        const handle =
          PROJECT_KEY === 'frax' ? '/api/v1/fraxtal/stacks' : '/api/v1/stacks';

        const { data } = await Gateways.noAuth.api.get(handle, {
          method: 'GET',
        });

        return {
          data: mapStacksFromApi(data),
        };
      }),
    }),
  }),
});
