import { makeStyles } from 'tss-react/mui';

export const usePaymentStatusModalsStyles = makeStyles()(theme => ({
  modal: {
    maxWidth: 600,
  },
  modalContent: {
    position: 'relative',
    padding: theme.spacing(10, 5, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  modalCloseBtn: {
    position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(5),
  },
  modalImage: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(2),
  },
  modalHint: {
    color: theme.palette.text.secondary,
  },
  modalBtn: {
    marginTop: theme.spacing(10),
  },

  failedModalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
  failedModalTitle: {
    fontSize: 35,
  },
  failedModalHint: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },

  paymentType: {
    marginBottom: theme.spacing(2),
  },

  poweredBy: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));
