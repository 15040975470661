import { IAccountInfo, IAccountInfoFormApi } from '../actions/types';

export function mapAccountInfoFromApi(
  payload: IAccountInfoFormApi,
): IAccountInfo {
  return {
    email: payload.email,
    companyName: payload.company_name,
    companyInfo: payload.company_info,
    companyTwitter: payload.company_twitter,
    isVerified: payload.is_verified,
  };
}
